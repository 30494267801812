import store from '@/store/index';
import router from '@/router/index';
router.beforeEach(async(to, from, next) => {
    const token =await store.getters.getToken; // 确保调用 getter 函数
    if (token) {
        if (to.path === '/login') {
            next('/'); // 用户已登录，从登录页重定向到首页
        } else {
            next(); // 用户已登录，继续访问原目标路由
        }
    } else {
        if (to.path === '/login'||to.path==='/share/shareVideo') {
            next(); // 用户未登录，且尝试访问登录页，允许访问
        } else {
            next('/login'); // 用户未登录，重定向到登录页
        }
    }
});